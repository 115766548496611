// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerContainer {
    width: 100%;
    background-color: transparent;
    height: 0rem;
    text-align: center;
    justify-self: flex-end;
    margin-top: auto;
}

.footerLogo {
    position: relative;
    height: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 735px) {
    .footerContainer {
        height: 5rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,mCAA2B;YAA3B,2BAA2B;IAC3B,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".footerContainer {\n    width: 100%;\n    background-color: transparent;\n    height: 0rem;\n    text-align: center;\n    justify-self: flex-end;\n    margin-top: auto;\n}\n\n.footerLogo {\n    position: relative;\n    height: 50%;\n    top: 50%;\n    transform: translateY(-50%);\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n@media only screen and (max-width: 735px) {\n    .footerContainer {\n        height: 5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
