// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    height: 100%;
    margin: 0;
    font-family: "Raleway", sans-serif;
    background-color: #777777;
    font-size: 12px;
}

html * {
    box-sizing: border-box;
}

#root {
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,kCAAkC;IAClC,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB","sourcesContent":["html, body {\n    height: 100%;\n    margin: 0;\n    font-family: \"Raleway\", sans-serif;\n    background-color: #777777;\n    font-size: 12px;\n}\n\nhtml * {\n    box-sizing: border-box;\n}\n\n#root {\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
