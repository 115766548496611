import * as React from 'react';

const EmbedScript = ({ src, onScriptLoaded = () => {} }: { src: string; onScriptLoaded: () => void }) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.onload = () => {
    onScriptLoaded();
  };
  script.onerror = (error) => {
    console.error('Error loading script:', error);
  };
  document.body.appendChild(script);

  return <div></div>; // the actual JSX to render after the script loads
};

export default EmbedScript;