import * as React from "react"

import { Footer } from "./footer/Footer";
import EmbedScript from "./EmbedScript";
import background from "./assets/back.png";

import "./Main.css";

export class Main extends React.Component {
    public componentDidMount() {
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    public render() {
        const handleScriptLoaded = () => {
            // console.log('Altegio widget script loaded');
        };

        return (
            <div className={"container"} style={{ backgroundImage: `url(${background})` }}>
                <div className="title">
                    <div>
                        Courage Mastery
                        <br />
                        Barbershop
                    </div>
                </div>
                <div>
                    <EmbedScript src="https://w831032.alteg.io/widgetJS" onScriptLoaded={handleScriptLoaded} />
                </div>
                <Footer />
            </div>
        );
    };

    private handleResize = () => {
        const windowWidth = window.innerWidth;
        const baseFontSize = windowWidth * 12 / 505;

        document.documentElement.style.fontSize = `${windowWidth < 505 ? baseFontSize : 12}px`;
    }
};
