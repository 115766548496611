// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.title {
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
    margin: 10.083rem 0 0.5rem;
    font-size: 5.9rem;
    font-family: cursive;
}
`, "",{"version":3,"sources":["webpack://./src/components/Main.css"],"names":[],"mappings":"AAAA;IACI,oBAAa;IAAb,qBAAa;IAAb,aAAa;IACb,4BAAsB;IAAtB,6BAAsB;IAAtB,8BAAsB;YAAtB,sBAAsB;IACtB,gBAAgB;IAChB,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;IACjB,0BAA0B;IAC1B,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    min-height: 100%;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.title {\n    text-align: center;\n    color: rgb(255, 255, 255);\n    font-weight: bold;\n    margin: 10.083rem 0 0.5rem;\n    font-size: 5.9rem;\n    font-family: cursive;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
