import * as React from 'react';

// import { LogoSvg } from "../header/logo";

import "./Footer.css";
import EmbedIframe from '../EmbedIframe';

export class Footer extends React.Component {
    render() {
        return (
            <div className="footerContainer">
                <div>
                    {/* <EmbedIframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d283.09110351048116!2d21.56335577925618!3d57.393103186331906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46f1c8fb20c55555%3A0x9b3eadb72c175de4!2sGan%C4%ABbu%20iela%202-3%2C%20Ventspils%2C%20LV-3601!5e0!3m2!1slv!2slv!4v1704712596368!5m2!1slv!2slv" allowFullScreen={false} /> */}
                </div>
            </div>
        );
    };
};
